import $ from 'jquery';
import './js/ajax.js';
import './js/filters.js'
import './scss/style.scss';

console.log(process.env.NODE_ENV);

$('#mobile-filter').click(function(event){
  event.preventDefault();
  console.log('click')
  $(".section-filters").toggleClass("show-display-block");
});

$(".selections .selectBox").click(function(){
  const list = $(this).parent().children( "ul" ); 
  list.toggleClass("show");
  list.on( "mouseleave", function() {
    list.removeClass("show");
  });
});
