import $ from 'jquery'

	const page = Number($('article').attr('data-page'))
	const amount = Number($('article').attr('data-page-amount'))

	function getParams(key){
    const params = {}
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    })
    return key?params[key]:params
  }

	$('.load-more').on('click', function(e) {
	  const that = $(this)
	  const page = $(this).data('page')
	  const cat = $(this).data('category')
	  const newPage = page + 1
	  const ajaxurl = that.data('url')
		const newCat = getParams('filter')
		
		console.log(page, amount)

	  $('.dots').addClass('loading')
	  that.addClass('hide')

		$.ajax({
				url: ajaxurl,
				type: 'POST',
				data: {
				page: page,
				cat: cat,
				action: 'ajax_script_load_more'
			},
			error: function(response) {
				console.log(response)
			},
			success: function(response) {
				if (response === 0) {
					$('.dots').removeClass('loading')
					that.addClass('hide')
				} else {
					if(page === (amount)) {
						$('.load-more').hide()
					}
					that.data('page', newPage).removeClass('hide').data('cat', newCat)
					$('.dots').removeClass('loading')
					$('#profile-grid').append(response.substr(response.length - 1, 1) === '0' ? response.substr(0, response.length - 1) : response)
				}
			}
		});
	});