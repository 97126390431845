import $ from 'jquery';

  let array = []
  const filter = getParams('filtro')
  const container = $('.filters')

  if(filter){
    array = filter.split(',');
    let decode = decodeURIComponent(array)
    decode = decode.replace(/,/g, '.')
    let res = decode.split(".");

    $('.profile').each((index, element) => {
      $('.profile').removeClass('show').addClass('hide')
      $(`.profile.${decode}`).removeClass('hide').addClass('show')     
    });

    res.map((classe, i) => { 
      $(`.filters.${classe}`).addClass('clicked')
      appendItens($(`.filters.${classe}`), classe)
    });
  }

  $('.filters').click((e) => onClick(e, array))

	function getParams(key) {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    });
    return key?params[key]:params
  }

  function onClick(e, array) {
		const area = e.currentTarget.getAttribute('data-area')
		const nivel = e.currentTarget.getAttribute('data-nivel')
    const local = e.currentTarget.getAttribute('data-local')
    const todos = e.currentTarget.getAttribute('data-todos')
    const active = e.currentTarget.getAttribute('class', 'active')

    if(area !== 'todos' && nivel !== 'todos' && local !== 'todos' && todos !== 'todos'){
      container.removeClass('active');
      $(e.currentTarget).addClass('active');

      const item = $(e.currentTarget);
      item.addClass('clicked')

      if(area) {
        array.push( area );
        appendItens(item, area);
      }
      if(nivel) {
        array.push( nivel );
        appendItens(item, nivel);
      }
      if(local) {
        array.push( local );
        appendItens(item, local);
      }
      
      history.pushState( {}, '', `?filtro=${array}` )

      if(active) {
        $(e.currentTarget).removeClass('active')
        array = $.grep(array, (value) => { return value })
				history.pushState( {}, '', `?filtro=${array}` )
      }

    } else if (area === 'todos' || nivel === 'todos' || local === 'todos' || todos === 'todos') {
      container.removeClass('active')
      $(e.currentTarget).addClass('active')
			array = [];
			history.pushState( {}, '', `?filtro=${array}` )
    }
  }

  $('#filtrar-itens').click(function(){
    location.reload()
  });

  $('#clear-all-itens').click(function(){
    array = [];
    $('.filters').removeClass('clicked')
	  history.pushState( {}, '', `?filtro=${array}` )
    location.reload()
  });

  $('.result').on('click', '.clear-filter', function() {
    let el;
    let decode = decodeURIComponent(array)
    let result = decode.split(',');

    const areaAttr = $(this).parent().attr('data-area');
    const nivelAttr = $(this).parent().attr('data-nivel');
    const localAttr = $(this).parent().attr('data-local');

    if (typeof areaAttr !== typeof undefined && areaAttr !== false) {
      el = areaAttr
      $(`[data-area=${areaAttr}]`).removeClass('clicked')
    } else if (typeof nivelAttr !== typeof undefined && nivelAttr !== false) {
      el = nivelAttr
      $(`[data-area=${nivelAttr}]`).removeClass('clicked')
    } else if (typeof localAttr !== typeof undefined && localAttr !== false) {
      el = localAttr
      $(`[data-area=${localAttr}]`).removeClass('clicked')
    }
    result = $.grep(result, (value) => { console.log(value); return value != el });
    history.pushState( {}, '', `?filtro=${result}` );
    $(this).parent().remove();
    location.reload()
  });


function appendItens(item, dataAttribute) {
  let type;

  if(item.parent().hasClass('area')) {
    type = 'area';
  } else if(item.parent().hasClass('nivel')) {
    type = 'nivel';
  } else if(item.parent().hasClass('local')) {
    type = 'local';
  }

  const divResul = item.parent().parent().children( ".result" );
  const svgIcon = "<svg class='clear-filter' width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0.00341797 7.38281L7.38225 0.00397717L7.99715 0.61888L0.618321 7.99772L0.00341797 7.38281Z' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M0.614746 0L3.38181 2.76706L2.76691 3.38197L-0.000156765 0.614903L0.614746 0Z' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M7.3855 7.99951L4.61844 5.23245L5.23334 4.61755L8.0004 7.38461L7.3855 7.99951Z' fill='white'/></svg>"
  divResul.append(`<li class='${dataAttribute}' data-${type}=${dataAttribute}>${item.text()} ${svgIcon}</li>`);
}

$(window).bind('scroll', function () {
  if ($(window).scrollTop() > 350 && $(window).width() > 850) {
      $('.aside-filters').addClass('fixed-filters');
      $('.profiles').addClass('profiles-filters-fixed');
  } else {
      $('.aside-filters').removeClass('fixed-filters');
      $('.profiles').removeClass('profiles-filters-fixed');
  }
});